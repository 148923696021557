import { practiceDBIcon, learningDBIcon } from '~/static/icons/index.js';

const TEST_TYPES = {
    QUICK_DIAGNOSTIC: 'gre-diagnostic-quick',
    FULL_DIAGNOSTIC: 'gre-diagnostic-full',
    FULL_LENGTH: 'gre',
    FULL_LENGTH_V2: 'gre-v2',
    MINI: 'gre-mini',
    TOPIC: 'gre-topic',
    TOPIC_VERBAL: 'gre-topic-verbal',
    TOPIC_QUANT: 'gre-topic-quant',
    GRE_LIVE_TEST: 'gre-live-full'
};

const LIVE_TEST_TIMINGS = {
    LIVE_TEST_VISIBILITY: new Date('August 17 2024 12:00:00 GMT+0530').getTime(),
    START_TIME: new Date('August 17 2024 13:45:00 GMT+0530').getTime(),
    END_TIME: new Date('August 17 2024 18:00:00 GMT+0530').getTime()
};

const PREMIUM_PLANS = {
    PREP_PLUS_1_MONTH: 'prep-plus-1-month',
    PREP_PLUS_3_MONTH: 'prep-plus-3-months-v2',
    PREP_PLUS_6_MONTH: 'prep-plus-6-months',
    PREP_PLUS_YOCKET_PRO: 'prep-plus-yocket-pro',
    PREP_PLUS_LIVECLASS: 'prep-plus-live-classes',
    PREP_PLUS_ONDEMAND: 'prep-plus-on-demand'

    // -------OLDER PLANS-------
    // PREP_PLUS: 'prep-plus-3-months',
    // PREP_PLUS_PRO: 'prep-plus-6-months-gre-vouchers'
};

const PROFILE_COMPLETION_LEVEL = {
    OTP_VERIFIED: 0,
    BASIC_DETAILS_FILLED: 1,
    COMPLETE: 2
};

const CHECKOUT_DROPOFF_FORMS = {
    PRACTICE_PLAN: 'https://tally.so/r/wd0PWz',
    ONDEMAND_PLAN: 'https://tally.so/r/3xDDOE',
    LIVECLASS_PLAN: 'https://tally.so/r/3El0zo'
};

const TRUSTPILOT_URL = 'https://www.trustpilot.com/review/prep.yocket.com';

const FEEDBACK_STATUS = {
    PENDING: 1,
    RESOLVED: 2,
    DISMISSED: 3
};

const TEST_SECTION_ID = {
    FULL_LENGTH: '9987'
};

const SOCIALS = {
    facebook: {
        icon: 'Facebook',
        alt: 'facebook',
        link: 'https://www.facebook.com/YocketApp/'
    },
    youtube: {
        icon: 'Youtube',
        alt: 'youtube',
        link: 'https://www.youtube.com/c/Yocket-app'
    },
    twitter: {
        icon: 'Twitter',
        alt: 'twitter',
        link: 'https://twitter.com/yocketapp'
    },
    instagram: {
        icon: 'Instagram',
        alt: 'instagram',
        link: 'https://www.instagram.com/yocketapp'
    },
    linkedin: {
        icon: 'Linkedin',
        alt: 'linkedin',
        link: 'https://www.linkedin.com/company/yocket'
    }
};

const USER_ONETIME_ACTIONS = {
    LEARNING_VIDEO_CTA_WALKTHROUGH: 1,
    PLANNER_V1_VIEW_PREFERENCE: 2,
    VOCAB_CONNECT_WALKTHROUGH: 3,
    CHECKOUT_OFFER_STATUS: 4,
    PRACTICE_DASHBOARD_BRIEFING: 5,
    LEARNING_DASHBOARD_BRIEFING: 6
};

const OFFER_CAMPAIGNS_HANDLER_STATUS = {
    SKIPPED: 1,
    CLAIMED: 2
};

const ACTION_STATUS = {
    COMPLETE: 1,
    INCOMPLETE: 2,
    PENDING: 3
};

const DASHBOARD_MODES = {
    PRACTICE: 1,
    LEARNING: 2
};

const DASHBOARD_TYPES = {
    FIRST: 'default',
    SECOND: 'personalized'
};

const CLASS_TYPES = {
    LIVE: 1,
    UPCOMING: 2,
    PAST: 3
};

const TEST_CARD_VIEW = {
    TOPIC_TEST: 1,
    MOCK_TEST: 2
};

const OTHER_LINKS = {
    CAREERS: 'https://careers.yocket.com/jobs/Careers',
    HELP_CENTER: 'https://support.yocket.com/support/home',
    NEW_SUPPORT_TICKET: 'https://support.yocket.com/support/tickets/new',
    YOCKET: 'https://yocket.com',
    PRIVACY_POLICY: '/privacy-policy'
};

const ANALYTICS_PLATFORMS = {
    WEBENGAGE: 'webEngage',
    GOOGLEANALYTICS: 'googleAnalytics'
};

const SIDE_MENU_LIST_CATEGORIES = {
    GRE: 'gre-guide-list',
    BLOG: '/blogs'
};

const PERMISSIONS = {
    GRE_RECORDED_CLASS_ACCESS: 'gre-recorded-class-till-feb',
    GRE_LIVE_CLASS_ACCESS: 'gre-live-classes',
    GRE_SELF_PACED_COURSE: 'gre-self-paced-course',
    // GRE_PLANNER: 'gre-priority-planner', // deprecated
    GRE_PLANNER_LEARNING_AID: 'gre-planner-learning-aid',
    GRE_PLANNER_PRACTICE_AID: 'gre-planner-practice-aid',
    VOCAB_BUILDER: 'gre-vocab-builder'
};

const ACQUISITION_AUTH_INTERVALS = {
    FIRST_INTERVAL: 30,
    SECOND_INTERVAL: 120
};

const ARTICLE_TYPE = {
    BLOG: 1,
    GUIDE: 2
};

const GRE_TOPICS = [
    { id: 1, topic: 'Overview', redirection: '/gre', title: 'Overview' },
    { id: 2, topic: 'Eligibility', redirection: '/gre/eligibility', title: 'Eligibility' },
    {
        id: 3,
        topic: 'Registration',
        redirection: '/gre/how-to-register',
        title: 'Registration'
    },
    { id: 4, topic: 'Pattern', redirection: '/gre/exam-pattern', title: 'Pattern' },
    { id: 5, topic: 'Score Chart and Related', redirection: '/gre/score-chart', title: 'Score Chart and Related' },
    { id: 6, topic: 'Syllabus', redirection: '/gre/syllabus', title: 'Syllabus' },
    { id: 7, topic: 'Exam Dates', redirection: '/gre/exam-dates', title: 'Exam Dates' },
    {
        id: 8,
        topic: 'Sample Papers',
        redirection: '/gre/sample-papers',
        title: 'Sample Papers'
    },
    { id: 9, topic: 'Resources', redirection: '/gre/book-and-resources', title: 'Resources' }
];



const DASHBOARD_REDIRECTION = [
    {
        id: 1,
        redirection: '/dashboard',
        title: 'Practice Dashboard',
        icon: practiceDBIcon
    },
    {
        id: 2,
        redirection: '/gre/how-to-register',
        title: 'Learning Dashboard',
        icon: learningDBIcon
    }
];

const PLANNER_RESOURCE_TYPE = {
    LECTURES: 1,
    PRACTICE_TESTS: 2,
    MOCKS: 3
};
const APPZI_SURVEY_KEYS = {
    LEARNING_SURVEY: 'dd332cf8-6297-4e82-9986-0e187aca4336'
};

export {
    TEST_TYPES,
    SOCIALS,
    OTHER_LINKS,
    PREMIUM_PLANS,
    FEEDBACK_STATUS,
    ANALYTICS_PLATFORMS,
    SIDE_MENU_LIST_CATEGORIES,
    GRE_TOPICS,
    DASHBOARD_MODES,
    USER_ONETIME_ACTIONS,
    OFFER_CAMPAIGNS_HANDLER_STATUS,
    ACTION_STATUS,
    PLANNER_RESOURCE_TYPE,
    PERMISSIONS,
    DASHBOARD_REDIRECTION,
    ARTICLE_TYPE,
    APPZI_SURVEY_KEYS,
    TEST_CARD_VIEW,
    DASHBOARD_TYPES,
    LIVE_TEST_TIMINGS,
    CLASS_TYPES,
    ACQUISITION_AUTH_INTERVALS,
    TEST_SECTION_ID,
    CHECKOUT_DROPOFF_FORMS,
    TRUSTPILOT_URL,
    PROFILE_COMPLETION_LEVEL
};
