import landingPageCtaPattern from './landingPageCtaPattern.svg';
import authFooterPattern from './BgPatterns/authFooterPattern.svg';
import dashboardNav from './dashboardNav.svg';
import error404 from './Error/error404.svg';
import error500 from './Error/error500.svg';
import skip from './skip.svg';
import hifive from './hifive.svg';
import sadSmiley from './sadSmiley.svg';
import circlesPurple from './circlesPurple.svg';
import circlesBlue from './circlesBlue.svg';
import clipboardTime from './clipboardTime.svg';
import authModalTestCard from './authModalTestCard.svg';
import circlesRed from './circlesRed.svg';
import receipt from './receipt.svg';
import qdScoreIndicatorHigh from './qdScoreIndicatorHigh.svg';
import qdScoreIndicatorLow from './qdScoreIndicatorLow.svg';
import planExpiryLock from './planExpiryLock.svg';
import noLiveClass from './noLiveClass.svg';
import noUpcomingClass from './noUpcomingClass.svg';
import noPastClass from './noPastClass.svg';
import testimonialsBgPattern from './testimonialsBgPattern.svg';
import testimonialBGPatternMobile from './testimonialBGPatternMobile.svg';
import videoOutlineGrayBg from './videoOutlineGrayBg.svg';
import assignmentBgGrayIcon from './assignmentBgGrayIcon.svg';
import resourceLockedBgGray from './resourceLockedBgGray.svg';
import checkGradient from './checkGradient.svg';
import assignmentBgWhitePurple from './assignmentBgWhitePurple.svg';
import resourceLockedPurpleBgWhite from './resourceLockedPurpleBgWhite.svg';
import practiceR1Completion from './practiceR1Completion.gif';
import lightBulb from './lightBulb.svg';
import authModalVocabIllustration from './authModalVocabIllustration.svg';
import wordListEmptyState from './wordListEmptyState.svg';
import vocabWalkthroughModalIllustration from './vocabWalkthroughModalIllustration.svg';
import liveClassFeature1 from './liveClassFeature1.svg';
import liveClassFeature2 from './liveClassFeature2.svg';
import liveClassFeature3 from './liveClassFeature3.svg';
import liveClassFeature4 from './liveClassFeature4.svg';
import dummyAvatar1 from './dummyAvatar1.png';
import dummyAvatar2 from './dummyAvatar2.png';
import dummyAvatar3 from './dummyAvatar3.png';
import onDemandFeature1 from './onDemandFeature1.png';
import onDemandFeature2 from './onDemandFeature2.png';
import onDemandFeature3 from './onDemandFeature3.png';
import onDemandFeature4 from './onDemandFeature4.png';
import onDemandFeature5 from './onDemandFeature5.png';
import onDemandFeature6 from './onDemandFeature6.png';

import practiceDashboardModalIllustration from './practiceDashboardModalIllustration.png';
import learningDashboardModalIllustration from './learningDashboardModalIllustration.png';

export {
    landingPageCtaPattern,
    authFooterPattern,
    dashboardNav,
    error404,
    error500,
    skip,
    hifive,
    sadSmiley,
    circlesPurple,
    circlesBlue,
    circlesRed,
    clipboardTime,
    authModalTestCard,
    receipt,
    qdScoreIndicatorHigh,
    qdScoreIndicatorLow,
    planExpiryLock,
    noLiveClass,
    noUpcomingClass,
    noPastClass,
    testimonialsBgPattern,
    testimonialBGPatternMobile,
    checkGradient,
    videoOutlineGrayBg,
    assignmentBgGrayIcon,
    resourceLockedBgGray,
    assignmentBgWhitePurple,
    resourceLockedPurpleBgWhite,
    practiceR1Completion,
    lightBulb,
    authModalVocabIllustration,
    wordListEmptyState,
    vocabWalkthroughModalIllustration,
    liveClassFeature1,
    liveClassFeature2,
    liveClassFeature3,
    liveClassFeature4,
    dummyAvatar1,
    dummyAvatar2,
    dummyAvatar3,
    onDemandFeature1,
    onDemandFeature2,
    onDemandFeature3,
    onDemandFeature4,
    onDemandFeature5,
    onDemandFeature6,
    practiceDashboardModalIllustration,
    learningDashboardModalIllustration
};
